.fadeOutText{
    color: #B0B0B0;
    opacity:0;
    transition: width 1s 1s, height 1s 1s, opacity 1s;
}

.fadeIn{
    color: #FFFFFF;
    opacity:1;
    transition: color 1s, opacity 1s;
}

.fadeIn path { fill: white; transition: fill 1s, opacity 1s; }
.fadeOut path {  transition: fill 1s, opacity 1s; }

.logoHover path {
    fill: white;
    stroke: white;
}

.energieDivineHover path {
    fill: white;
    stroke: black;
}